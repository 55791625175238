import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Box } from '@mui/material'

interface Props {
  open: boolean
  media: string[]
  onClose: () => void
  title?: string
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

export const ContentCheckModal: React.FC<Props> = ({ open, media, onClose, title = 'Uploaded Media' }) => {
  const getMediaType = (url: string): 'image' | 'video' | 'unknown' => {
    if (url.toLowerCase().includes('video')) return 'video'
    if (url.toLowerCase().includes('image')) return 'image'
    return 'unknown'
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, position: 'relative' }}>
          <Carousel responsive={responsive} infinite={false} removeArrowOnDeviceType={['tablet', 'mobile']}>
            {media.map((url, index) => {
              const mediaType = getMediaType(url)

              return (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 8px' }}>
                  {mediaType === 'image' ? (
                    <img
                      src={url}
                      alt={`Media ${index + 1}`}
                      style={{
                        width: '100%',
                        maxHeight: '400px',
                        objectFit: 'contain',
                        borderRadius: '8px'
                      }}
                      onError={e => (e.currentTarget.style.display = 'none')}
                    />
                  ) : mediaType === 'video' ? (
                    <video
                      src={url}
                      controls
                      style={{
                        width: '100%',
                        maxHeight: '400px',
                        borderRadius: '8px',
                        backgroundColor: '#000'
                      }}
                    />
                  ) : (
                    <Typography color='error'>Unsupported media format</Typography>
                  )}
                </Box>
              )
            })}
          </Carousel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
