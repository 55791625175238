import React from 'react';
import { Box, Card, Collapse, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

export const StoryBoard = ({ content }: { content: any }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const hasContent = (value: any) => {
    return (
      (typeof value === 'string' && value.trim().length > 0) || // Non-empty string
      (typeof value === 'number' && !isNaN(value)) || // Valid number
      (Array.isArray(value) && value.length > 0) || // Non-empty array
      (value && typeof value === 'object' && Object.keys(value).length > 0) // Non-empty object
    );
  };

  const renderPhotos = (photos: any[]) => {
    return (
      <Box>
        {photos.map((photo, index) => (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Foto {index + 1}:
            </Typography>
            <Typography variant="body1" sx={{ marginLeft: 2 }}>
              {photo.description}
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: 2, fontStyle: 'italic' }}>
              Format: {photo.format}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const renderCard = (title: string, field: string) => {
    if (!content?.[field]?.length) return null;
    return (
      <Card key={field} sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          {title}
        </Typography>
        <Collapse in={checked} collapsedSize={0}>
          {field === 'photos'
            ? renderPhotos(content[field])
            : field === 'moodboard'
            ? renderMoodboardContent(content[field])
            : content[field]}
        </Collapse>
      </Card>
    );
  };

  const renderMoodboardContent = (moodboard: any) => {
    const itemHeight = 150;

    if (Array.isArray(moodboard)) {
      return (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {moodboard.map((item, index) => (
            <Box
              key={index}
              sx={{
                flex: '0 1 calc(20% - 16px)',
                maxWidth: 'calc(20% - 16px)',
                height: itemHeight,
                borderRadius: 4,
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              {item.endsWith('.jpg') || item.endsWith('.png') || item.endsWith('.jpeg') ? (
                <img
                  src={item}
                  alt={`moodboard-${index}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: 4,
                  }}
                />
              ) : (
                <ReactPlayer
                  url={item}
                  controls
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 4 }}
                />
              )}
            </Box>
          ))}
        </Box>
      );
    }

    return moodboard.endsWith('.jpg') || moodboard.endsWith('.png') || moodboard.endsWith('.jpeg') ? (
      <img
        src={moodboard}
        alt="moodboard"
        style={{
          width: '50%',
          height: `${itemHeight}px`,
          objectFit: 'cover',
          borderRadius: 4,
        }}
      />
    ) : (
      <ReactPlayer
        url={moodboard}
        controls
        width="100%"
        height={`${itemHeight}px`}
        style={{ borderRadius: 4 }}
      />
    );
  };

  const fields = [
    { title: 'Scenes', field: 'scenes' },
    { title: 'Mentions', field: 'mentions' },
    { title: 'Feedback', field: 'feedback' },
    { title: 'Moodboard', field: 'moodboard' },
    { title: 'Photos', field: 'photos' }, // Add Photos field
  ];

  return (
    <Box>
      {Object.keys(content)
        .filter((key) => ['scenes', 'mentions', 'feedback', 'moodboard', 'photos'].includes(key))
        .some((key) => hasContent(content[key])) && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="body1" sx={{ marginRight: 2 }}>
            Auftrags Details einblenden
          </Typography>
          <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="" />
        </Box>
      )}
      {checked && fields.map(({ title, field }) => renderCard(title, field))}
    </Box>
  );
};
