import { Button, Stack, Link, Typography, Paper, Box } from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import ReactPlayer from 'react-player'
import styles from '../../style/influencers.module.css'
import { StoryBoard } from './StoryBoard'
import CampaignService from '../../service/CampaignService'
import { ContentCheckModal } from './ContentCheckModal'

type Content = Awaited<ReturnType<typeof CampaignService.getContentCheck>>[number]

type Props = {
  content: Content
  setContents: Dispatch<SetStateAction<any[]>>
  onManage: (content: any, action: any) => void
  key: string
}

const Player = ({ url }: { url: string }) => (
  <Stack width='100%'>
    <ReactPlayer className={styles.profilePic} controls={true} url={url} width='100%' light height={150} />
  </Stack>
)

export const InfluencerContentGrid = ({ content, setContents, onManage, key }: Props) => {
  const [open, setOpen] = useState(false)
  const [openVideos, setOpenVideos] = useState([])
  const columns: GridColDef[] = [
    {
      field: 'information',
      headerName: 'Information',
      width: 250,
      cellClassName: 'wrap-text',
      flex: 1,
      renderCell: params => (
        <Box sx={{ display: 'block', mt: 2, fontWeight: 'bold' }}>
          <Typography variant='body2'>
            {' '}
            <Link
              variant='body2'
              target='_blank'
              href={`${process.env.REACT_APP_WEB_URL}/content/${params.row.brandId}/dashboard`}
              sx={{ display: 'flex', mb: 1, fontWeight: 'bold' }}>
              Link to the brand
            </Link>
          </Typography>
        </Box>
      )
    },
    {
      field: 'briefing',
      headerName: 'Briefing/Storyboard',
      width: 150,
      cellClassName: 'wrap-text',
      flex: 1,
      renderCell: params => (
        <Box>
          <Link
            variant='body2'
            target='_blank'
            href={`/influencers?filterModel=%257B%2522items%2522%253A%255B%257B%2522field%2522%253A%2522influencerId%2522%252C%2522operator%2522%253A%2522contains%2522%252C%2522id%2522%253A94293%252C%2522value%2522%253A%2522
                ${params.row.influencerId}
                %2522%252C%2522fromInput%2522%253A%2522%253Ar6n%253A%2522%257D%255D%257D`}
            sx={{ display: 'block', mb: 1, fontWeight: 'bold', mt: 2 }}>
            Link to influencer
          </Link>
          <Link
            variant='body2'
            target='_blank'
            href={`${process.env.REACT_APP_WEB_URL}/content/${params.row.brandId}/${params.row.campaignId}/creators`}
            sx={{ display: 'block', mb: 1, fontWeight: 'bold' }}>
            Link to campaign
          </Link>
          <p style={{ marginBottom: 8 }}>
            <Link variant='body2' fontWeight={'bold'}>
              Video Länge:
            </Link>{' '}
            <span style={{ fontWeight: 'bold' }}>{params.row.videoDuration}s</span>
          </p>
          <p style={{ marginBottom: 8 }}>
            <Link variant='body2' fontWeight={'bold'}>
              Format:
            </Link>{' '}
            <span style={{ fontWeight: 'bold' }}>{params.row.videoFormat}</span>
          </p>
          <p style={{ marginBottom: 8 }}>
            <Link variant='body2' fontWeight={'bold'}>
              Sprache:
            </Link>{' '}
            <span style={{ fontWeight: 'bold' }}>{params.row.language}</span>
          </p>
        </Box>
      )
    },
    {
      field: 'upload2',
      headerName: 'Upload 2',
      width: 150,
      cellClassName: 'wrap-text',
      flex: 1,
      renderCell: params => {
        const { secondContentLinks, secondContent } = params.row
        if (!secondContent && (!secondContentLinks || secondContentLinks?.length === 0)) return 'No videos available'
        return secondContentLinks?.length > 1 ? (
          <Button
            variant='contained'
            color='info'
            sx={{ mt: 2 }}
            onClick={() => {
              setOpen(true)
              setOpenVideos(secondContentLinks)
            }}>
            View Content
          </Button>
        ) : (
          <Player url={secondContent} />
        )
      }
    },
    {
      field: 'firstContent',
      headerName: 'Upload 1',
      width: 150,
      cellClassName: 'wrap-text',
      flex: 1,
      renderCell: params => {
        const { firstContentLinks, firstContent } = params.row
        if (!firstContent && (!firstContentLinks || firstContentLinks?.length === 0)) return 'No content available'
        return firstContentLinks?.length > 1 ? (
          <Button
            variant='contained'
            color='info'
            sx={{ mt: 2 }}
            onClick={() => {
              setOpen(true)
              setOpenVideos(firstContentLinks)
            }}>
            View Content
          </Button>
        ) : (
          <Player url={firstContent} />
        )
      }
    },
    {
      field: 'feedback',
      headerName: 'Feedback',
      width: 150,
      cellClassName: 'wrap-text',
      flex: 1,
      renderCell: params => <Typography variant='body2'>{params.row.feedback}</Typography>
    }
  ]

  const rows = [content].map(item => ({
    id: `${item.campaignId}_${item.influencerId}`,
    ...item
  }))

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <p>
        <span style={{ fontWeight: 'bold' }}>Influencer: </span>
        {content.influencerName}
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Auftragsname: </span>
        {content.title}
      </p>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={() => 'auto'}
        hideFooter
        disableColumnMenu
        sx={{
          mb: 2,
          '& .wrap-text': {
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }
        }}
      />
      <Stack direction='row' spacing={2} justifyContent='center'>
        <Button variant='contained' color='error' onClick={() => onManage(content, 'deny')}>
          {content.secondContent ? 'Deny' : 'Ban'}
        </Button>
        <Button variant='contained' color='success' onClick={() => onManage(content, 'accept')}>
          Annehmen
        </Button>
        <Button variant='contained' color='warning' onClick={() => onManage(content, 'reset')}>
          Reset
        </Button>
        <Button variant='contained' color='secondary' onClick={() => onManage(content, 'upload')}>
          Upload
        </Button>
        <Button variant='contained' color='info' onClick={() => onManage(content, 'comment')}>
          Comment
        </Button>
      </Stack>
      <StoryBoard content={content} />
      <ContentCheckModal
        open={open}
        media={openVideos}
        onClose={() => {
          setOpen(false)
          setOpenVideos([])
        }}
      />
    </Paper>
  )
}
