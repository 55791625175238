/* eslint-disable @typescript-eslint/no-explicit-any */

import { Auth } from 'aws-amplify'
import request, { Response } from 'superagent'
import type * as AdminBusiness from '../../getnano-backend/handlers/admin/businessHandler'
import type * as AdminInfluencer from '../../getnano-backend/handlers/admin/influencerHandler'
import type * as AdminTransactions from '../../getnano-backend/handlers/admin/transactionHandler'
import type * as AdminComments from '../../getnano-backend/handlers/admin/commentHandler'
import type * as AdminChat from '../../getnano-backend/handlers/admin/chatHandler'
import { API_ROOT } from './api-config'
import { check } from './util'

type Admin = typeof AdminBusiness & typeof AdminInfluencer & typeof AdminTransactions & typeof AdminComments & typeof AdminChat

type RequestReturnType<T> = Awaited<
  T extends { request: (...args: any) => infer R } ? (Awaited<R> extends new () => { execute: (...args: any) => infer R } ? R : R) : never
>
type RRT<Key extends keyof Admin> = RequestReturnType<Admin[Key]>
type Args<T> = T extends { validateEvent: (...args: any) => infer Return } ? Return : never

const handleErrors = async (err: any) => {
  console.log(err)
  if (err && err.response && err.response.status === 401) {
    await Auth.signOut()
    localStorage.clear()
  }
  return err
}

const responseBody = (res: Response) => res.body

export class Requests {
  constructor(public baseUrl: string) {}

  private async authHeaderValue() {
    const cognitoUser = await Auth.currentSession()
    return cognitoUser.getIdToken().getJwtToken()
  }

  replacePathAndQueryPlaceholders(url: string, dontCreateQuery: boolean, original: any | undefined) {
    const originalUrl = url
    const data = JSON.parse(JSON.stringify(original))
    Object.keys(data).forEach(k => data[k] === '' && delete data[k]) // clean undefined values
    const entries = Object.entries(data)
    const matches: RegExpMatchArray[] = Array.from((url as any).matchAll(/{(.*?)}/g))
    for (const [match, key] of matches) {
      const [dataKey, value] = entries.find(([dataKey]) => key.toLowerCase() == dataKey.toLowerCase()) ?? []
      check(dataKey != null && typeof value == 'string', `Invalid path parameter for ${key} ${originalUrl}`)
      url = url.replace(match, value)
      delete data[dataKey]
    }
    if (dontCreateQuery) return url
    const q = new URLSearchParams(data).toString()
    if (q) url += `?${q}`
    return url
  }

  getCustom = <K extends keyof Admin>(url: string) => {
    type RequestData = Args<Admin[K]>
    return async (data: RequestData): Promise<RRT<K>> =>
      request
        .get(`${API_ROOT()}${this.replacePathAndQueryPlaceholders(url, false, data)}`)
        .set('Authorization', `${await this.authHeaderValue()}`)
        .on('error', handleErrors)
        .then(responseBody)
  }

  postCustom = <K extends keyof Admin>(url: string) => {
    type RequestData = Args<Admin[K]>
    return async (data: RequestData): Promise<RRT<K>> =>
      request
        .post(`${API_ROOT()}${this.replacePathAndQueryPlaceholders(url, true, data)}`, data as any)
        .set('Authorization', `${await this.authHeaderValue()}`)
        .set('Content-Type', 'application/json')
        .on('error', handleErrors)
        .then(responseBody)
  }

  patchCustom = <K extends keyof Admin>(url: string) => {
    type RequestData = Args<Admin[K]>
    return async (data: RequestData): Promise<RRT<K>> =>
      request
        .patch(`${API_ROOT()}${this.replacePathAndQueryPlaceholders(url, true, data)}`, data as any)
        .set('Authorization', `${await this.authHeaderValue()}`)
        .set('Content-Type', 'application/json')
        .on('error', handleErrors)
        .then(responseBody)
  }

  async del(url: string) {
    return request
      .del(`${this.baseUrl}${url}`)
      .set('Authorization', await this.authHeaderValue())
      .on('error', handleErrors)
      .then(responseBody)
  }

  async get(url: string, body?: any) {
    if (body == null)
      return request
        .get(`${this.baseUrl}${url}`)
        .set('Authorization', await this.authHeaderValue())
        .on('error', handleErrors)
        .then(responseBody)
    return request
      .get(`${this.baseUrl}${url}`)
      .query({ body: JSON.stringify(body) })
      .set('Authorization', await this.authHeaderValue())
      .on('error', handleErrors)
      .then(responseBody)
  }

  async post(url: string, body?: any) {
    return request
      .post(`${this.baseUrl}${url}`, body)
      .set('Authorization', await this.authHeaderValue())
      .set('Content-Type', 'application/json')
      .on('error', handleErrors)
      .then(responseBody)
  }
}
export const requests = new Requests(API_ROOT())

const Authentication = {
  current: () => Auth.currentAuthenticatedUser(),
  currentSession: () =>
    Auth.currentSession()
      .then(data => console.log(data))
      .catch(err => console.log(err)),
  login: (email: string, password: string) => Auth.signIn(email, password),
  completeNewPassword: (user: any, password: string) => Auth.completeNewPassword(user, password, {}),
  logout: () => Auth.signOut({ global: true })
}

const Campaigns = {
  getAll: requests.getCustom<'getAllCampaigns'>(`/admin/campaigns/v2`),
  getCampaignById: requests.getCustom<'getCampaignById'>(`/admin/campaigns/v2/{campaignId}`),
  updateCampaign: requests.postCustom<'updateCampaign'>(`/admin/campaigns/v2/{campaignId}`),
  verifyCampaign: requests.postCustom<'verifyCampaign'>(`/admin/business/{brandId}/{campaignId}/active`),
  setCampaignOffline: requests.postCustom<'setCampaignOffline'>(`/admin/business/{brandid}/{campaignid}/offline`),
  searchCampaign: requests.postCustom<'searchCampaign'>(`/admin/business/campaign`),
  setPriority: requests.postCustom<'setPriority'>(`/admin/campaign/priority/{brandId}/{campaignId}`),
  getAllUneditedContent: requests.getCustom<'getAllUneditedContent'>(`/admin/getAllUneditedContent`),
  sendEditedContent: requests.postCustom<'sendEditedContent'>('/admin/sendEditedContent'),
  getContentCheck: requests.getCustom<'getContentCheck'>(`/admin/content-check`),
  approveInfluencerContent: requests.postCustom<'approveInfluencerContent'>('/admin/ugc/approveContent'),
  getTodoOverview: requests.getCustom<'getTodoOverview'>('/admin/getTodoOverview'),
  setTodoEndDate: requests.postCustom<'setTodoEndDate'>(`/admin/setTodoEndDate`),
  deleteCampaignInfluencer: requests.postCustom<'deleteCampaignInfluencer'>(`/admin/delete-ci`),
  getContentForInternalFeedback: requests.getCustom<'getContentForInternalFeedback'>(`/admin/internal-feedback`),
  setInternalFeedback: requests.postCustom<'setInternalFeedback'>(`/admin/internal-feedback`),
  deleteCampaign: requests.postCustom<'deleteCampaign'>(`/admin/campaign`),
  getMediaUploadUrl: requests.getCustom<'getMediaUploadUrl'>(`/admin/reset/url`),
  getEditedMediaUploadUrl: requests.getCustom<'getEditedMediaUploadUrl'>(`/admin/edited/url`)
}

const Influencers = {
  delete: requests.postCustom<'deleteInfluencer'>(`/admin/influencers/delete`),
  getAllNew: requests.getCustom<'getAllNewInfluencers'>(`/admin/v2/influencers/new`),
  getAll: requests.postCustom<'getAllInfluencers'>(`/admin/influencers`),
  verifyUgc: requests.postCustom<'verifyInfluencer'>(`/admin/influencers/{influencerid}/verified`),
  denyInfluencer: requests.postCustom<'denyInfluencer'>(`/admin/influencers/{influencerid}/deny`),
  toggleContentCv: requests.postCustom<'toggleContentCv'>(`/admin/influencers/content`),
  uploadCredit: requests.postCustom<'uploadCredit'>(`/admin/influencers/{influencerid}/credit`),
  getInfluencer: requests.getCustom<'getInfluencer'>(`/admin/influencers/{influencerid}`),
  getCampaignsOfCreator: requests.getCustom<'getCampaignsOfCreator'>(`/admin/influencers/{influencerid}/campaigns`),
  resetFeedback: requests.postCustom<'resetFeedback'>(`/admin/influencers/resetfeedback`),
  getInvoices: requests.getCustom<'getInvoices'>(`/admin/influencers/{influencerId}/invoices`),
  updateInfluencer: requests.postCustom<'updateInfluencer'>(`/admin/influencers/{influencerId}`),
  spoofAsInfluencer: requests.postCustom<'spoofAsInfluencer'>(`/admin/influencers/spoof`)
}

const Transactions = {
  getTransactions: requests.getCustom<'getTransactions'>(`/admin/transactions`),
  generateInvoices: requests.postCustom<'generateInvoices'>('/admin/transactions/invoices'),
  markAsSent: requests.postCustom<'markTransactionsAsFulfilled'>('/admin/transactions/sent')
}

const Comments = {
  addComment: requests.postCustom<'addComment'>(`/admin/comments`)
}

const Chat = {
  getConversations: requests.getCustom<'getConversations'>('/admin/chat/conversations'),
  createConversation: requests.postCustom<'createConversation'>('/admin/chat/conversation'),
  getMessages: requests.getCustom<'getMessages'>('/admin/chat/messages'),
  sendMessage: requests.postCustom<'sendMessage'>('/admin/chat/message'),
  markAsRead: requests.postCustom<'markAsRead'>('/admin/chat/read'),
  markAsUnread: requests.postCustom<'markAsUnread'>('/admin/chat/unread'),
  getChatMediaUploadUrl: requests.getCustom<'getChatMediaUploadUrl'>('/admin/chat/message/url'),
  getChatPresets: requests.getCustom<'getChatPresets'>('/admin/chat/presets'),
  saveChatPreset: requests.postCustom<'saveChatPreset'>('/admin/chat/presets'),
  deleteChatPreset: requests.postCustom<'deleteChatPreset'>('/admin/chat/presets/delete')
}

const Todo = {
  getTodoOverview: requests.getCustom<'getTodoOverview'>('/admin/getTodoOverview'),
  setTodoEndDate: requests.postCustom<'setTodoEndDate'>('/admin/setTodoEndDate'),
  resetUploadTodo: requests.postCustom<'resetUploadTodo'>(`/admin/resetUploadTodo`),
  getDashboard: requests.getCustom<'getDashboard'>('/admin/dashboard')
}

export default {
  Authentication,
  Campaigns,
  Influencers,
  Transactions,
  Comments,
  Chat,
  Todo
}
